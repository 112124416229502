<!-- Drawer.vue -->
<template>
    <div v-if="show" class="drawer">
      <div class="drawer-content">
        <h3 style="margin-top: 10px;">补充发票</h3>
        <div style="height: 14px;">{{ selectedFile.name }}</div>
        <div class="form">
            <div class="form-row" v-if="employeeClientModel">
              <!-- 如果是员工端登录，则需要搜索客户 -->
              <div class="form-group">
                <!-- <label for="queryCustomer"><span class="required">*</span>搜索客户</label> -->
                <input type="text" id="queryCustomer" v-model="queryCustomer" placeholder="输入客户名称 / 客户编码进行搜索" @focus="showCustomersSuggestions = true"  @input="debouncedFetchCustomersSuggestions"/>
                <!-- <button class="btn btn-default" @click="clearQueryCustomer">清除</button> -->
              </div>
            </div>
            <div class="autocomplete-container">
              <div class="form-group">
                <div class="autocomplete-container">
                  <ul v-if="showCustomersSuggestions">
                    <li v-for="(suggestion, index) in suggestionCustomers" :key="index"
                      @click="selectCustomersSuggestion(suggestion)" @mouseover="highlightIndex = index"
                      @mouseleave="highlightIndex = -1">
                      {{ suggestion.name }} - {{ suggestion.code }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-row">
                <div class="form-group">
                    <label for="customerName"><span class="required">*</span>客户名称</label>
                    <input type="text" id="customerName" v-model="settings.customerName" placeholder="客户名称" :readonly="customerReadonly"/>
                </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                  <label for="customerCode"><span class="required">*</span>客户编码</label>
                  <input type="text" id="customerCode" v-model="settings.customerCode" placeholder="客户编码" :readonly="customerReadonly"/>
              </div>
          </div>
            <div class="form-row">
                <div class="form-group">
                    <label for="customerOrderNo"><span class="required">*</span>客户单号</label>
                    <input type="text" id="customerOrderNo" v-model="settings.customerOrderNo" placeholder="客户单号" @input="triggerChangeCustomerOrderNo" @blur="checkDuplicateCustomerOrderNo(settings.customerOrderNo)" />
                </div>
            </div>
            <div class="form-row" v-if="!employeeClientModel">
                <div class="form-group">
                    <label for="count"><span class="required">*</span>包裹件数</label>
                    <input type="number" min=1 value=1 step=1 id="count" v-model="settings.packageCount" placeholder="包裹件数" :readonly="true"/>
                </div>
            </div>
            <div class="form-row" v-if="!employeeClientModel">
              <div class="form-group">
                  <label for="packageTypeSelect"><span class="required">*</span>包裹类型</label>
                  <select id="packageTypeSelect" v-model="settings.packageType" class="select" :disabled="true">
                      <!-- 默认显示的提示选项，value为空，显示提示信息 -->
                      <option selected disabled value=''>-- 请选择包裹类型 --</option>
                      <option v-for="packageType in suggestionPackageType" :key="packageType" :value="packageType.type">{{ packageType.name }}</option>
                  </select>
              </div>
            </div>
            <div class="form-row" v-if="!employeeClientModel">
              <div class="form-group">
                <label for="contry"><span class="required">*</span>收货国家</label>
                <input type="text" id="countrySelect" v-model="settings.deliveryCountry" @input="debouncedFetchCountriesSuggestions"
                  @focus="showCountriesSuggestions = true" placeholder="输入关键词搜索" :readonly="true"/>
              </div>
            </div>
            <div class="autocomplete-container" >
              <div class="form-group">
                <div class="autocomplete-container">
                  <ul v-if="showCountriesSuggestions">
                    <li v-for="(suggestion, index) in suggestionCountries" :key="index"
                      @click="selectContriesSuggestion(suggestion)" @mouseover="highlightIndex = index"
                      @mouseleave="highlightIndex = -1">
                      {{ suggestion.nameCn }} - {{ suggestion.nameEn }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-row" v-if="!employeeClientModel">
              <div class="form-group">
                <label for="channelSelect"><span class="required">*</span>收货渠道</label>
                <input type="text" id="channelSelect" v-model="settings.shippingChannel" @input="debouncedFetchChannelsSuggestions"
                  @focus="showChannelsSuggestions = true" placeholder="输入关键词搜索" :readonly="true" :display="false"/>
              </div>
            </div>
            <div class="autocomplete-container">
              <div class="form-group">
                <div class="autocomplete-container">
                  <ul v-if="showChannelsSuggestions">
                    <li v-for="(suggestion, index) in suggestionChannels" :key="index"
                      @click="selectChannelsSuggestion(suggestion)" @mouseover="highlightIndex = index"
                      @mouseleave="highlightIndex = -1">
                      {{ suggestion }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- 
            <div class="form-row">
              <div class="form-group">
                  <label for="channel"><span class="required">*</span>发货渠道</label>
                  <select id="channelSelect" v-model="settings.shippingChannel" class="select">
                      <option selected disabled value=''>-- 请选择您的发货渠道 --</option>
                      <option v-for="channel in suggestionChannels" :key="channel" :value="channel">{{ channel }}</option>
                  </select>
              </div>
            </div>
             -->
            <div class="form-row" v-if="!employeeClientModel">
              <div class="form-group">
                  <label>报关方式</label>
                  <select id="customDeclarationMethodSelect" v-model="settings.customDeclarationMethod" class="select" :disabled="true">
                      <!-- 默认显示的提示选项，value为空，显示提示信息 -->
                      <option selected disabled value=''>-- 请选择您的报关方式 --</option>
                      <option v-for="method in suggestionCustomDeclarationMethod" :key="method" :value="method.type">{{ method.name }}</option>
                  </select>
              </div>
            </div>
            <div class="form-row" v-if="!employeeClientModel">
              <div class="form-group">
                  <label>货物特性</label>
                  <select id="cargoCharacteristicsListSelect" v-model="settings.cargoCharacteristics" class="select" disabled="true">
                      <!-- 默认显示的提示选项，value为空，显示提示信息 -->
                      <option selected disabled value=''>-- 请选择货物特性 --</option>
                      <option v-for="cargoCharacteristics in suggestionCargoCharacteristics" :key="cargoCharacteristics" :value="cargoCharacteristics">{{ cargoCharacteristics }}</option>
                  </select>
                  <!-- <select id="cargoCharacteristicsListSelect" v-model="settings.cargoCharacteristics" class="select" disabled>
                    <option value="">-- 请选择货物特性 --</option>
                  </select> -->
                  <!-- <div class="checkbox-group">
                    <div v-for="cargoCharacteristics in suggestionCargoCharacteristics" :key="cargoCharacteristics" class="checkbox-item">
                      <input type="checkbox" :id="'cargoCharacteristics' + cargoCharacteristics" v-model="settings.cargoCharacteristics" :value="cargoCharacteristics">
                      <label :for="'cargoCharacteristics' + cargoCharacteristics">{{ cargoCharacteristics }}</label>
                    </div>
                  </div> -->
              </div>
            </div>
            <button @click="saveSettings">保存</button>
            <button @click="closeDrawer">取消</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import { fetchReceiptChannel, fetchAutoCompleteContry, fetchAutoCompleteClient, /** existsWaybillOrderNo, */ syncExistsWaybillOrderNoInCustomer } from '@/components/domain/apis/ProxyItdida.js';

  
  export default {
    data() {
      return {
        show: false,                     // 是否显示抽屉
        employeeClientModel: false,      // 是否是员工端登录
        queryCustomer: '',               // 搜索客户名称 / 客户编码
        showCustomersSuggestions: false, // 是否显示客户提示列表
        showCountriesSuggestions: false, // 是否显示收货国家提示词
        showChannelsSuggestions: false,  // 是否显示收货渠道提示列表
        customerReadonly:false,
        customerOrderNoChanged: true,
        selectedFile: null,
        fileIndex: null,
        settings: {
          customerName: '',
          customerCode: '',
          customerOrderNo: '',
          clientModel: {},
          deliveryCountry: '美国 - U.S.A.',
          deliveryCountryModel: {
            code:'US',
            id:'61b5bd0e4f0f463b98582a13e76a1ff8',
            nameCn:'美国',
            nameEn:'U.S.A.'
          },
          shippingChannel: '香港DHL-T价',
          packageCount: 1,
          packageType: 1,
          customDeclarationMethod: 0,
          cargoCharacteristics:'普货',
          cargoCharacteristicsList: [],
          choosed: false,
        },
        lastCustomerOrderNo: '',
        suggestionCustomers: [],
        suggestionCountries: [],
        suggestionChannels: [],
        suggestionCustomDeclarationMethod: [{"type": 0, "name": "其他"}, {"type": 1, "name": "单独报关"}],
        suggestionCargoCharacteristics: ["普货", "带电","原发票/原抬头"],
        suggestionPackageType: [{"type": 0, "name": "文件"}, {"type": 1, "name": "箱头"}, {"type": 2, "name": "包裹袋"}],
      };
    },
    computed: {
      ...mapState(['files']),
      ...mapState(['fileSettings']),
    },
    methods: {
      ...mapActions(['updateFileSettings', 'checkFileSettings']),
      expectCustomerOrderNo(filename) {
        // // 第一步：去除汉字
        // var noChinese = filename.replace(/[\u4e00-\u9fa5]/g, '');
        // // 第二步：去除最后一个 "." 之后的所有内容
        // var result = noChinese.replace(/\.[^.]*$/, '');
        // // 第三步：去除所有空格
        // var r3 = result.replace(/\s+/g, '');
        // // 第四步：去除所有特殊字符"-#$%&'()*+,-./:;<=>?@[\\]^_`{|}~",包括全半角连接符
        // var r4 = r3.replace(/[-#$%&'()*+,-\u2014\u2013./:;<=>?@[\\]^_`{|}~]/g, '');
        // return r4;
        // 第一步：去除文件扩展名
        var withoutExtension = filename.replace(/\.[^/.]+$/, '');
        // 第二步：去除汉字
        var noChinese = withoutExtension.replace(/[\u4e00-\u9fa5]/g, '');
        // 第三步：去除所有空格
        var noSpaces = noChinese.replace(/\s+/g, '');
        // 第四步：去除特定的连接字符和其它非英数字字符
        var cleaned = noSpaces.replace(/[^a-zA-Z0-9]/g, '');
        // 第五步：抓取预期的英文和数字组合
        var match = cleaned.match(/[a-zA-Z0-9]+/);
        return match ? match[0] : '';
      },
      getLongestAlphanumericSubstring(filename) {
        // 第一步：去除文件扩展名
        var str = filename.replace(/\.[^/.]+$/, '');
        // 使用正则表达式匹配字符串中的字母和数字
        const regex = /[a-zA-Z0-9]+/g;
        // 找到所有匹配的子串
        let matches = str.match(regex);
        // 如果没有匹配到任何字母或数字，返回空字符串
        if (!matches) return '';
        // 找到最长的匹配项
        return matches.reduce((longest, current) =>
          current.length > longest.length ? current : longest
        );
      },

      openDrawer(file, index) {
        this.selectedFile = file;
        this.fileIndex = index;
        this.settings = { ...this.getFileSettings(index) };
        this.settings.deliveryCountry = '美国 - U.S.A.';
        this.settings.deliveryCountryModel = {
            code:'US',
            id:'61b5bd0e4f0f463b98582a13e76a1ff8',
            nameCn:'美国',
            nameEn:'U.S.A.'
          };
        this.settings.shippingChannel= '香港DHL-T价';
        this.show = true;
        this.lastCustomerOrderNo = '';
        var userInfo = JSON.parse(localStorage.getItem('user_info'));
        if (!this.settings.customerOrderNo) {
          this.settings.customerOrderNo = this.expectCustomerOrderNo(this.getLongestAlphanumericSubstring(this.selectedFile.name));
          this.checkDuplicateCustomerOrderNo(this.settings.customerOrderNo);
        }
        // var client_id = '';
        if (userInfo) {
          if (userInfo.clientModel && userInfo.clientModel.id) {
            this.settings.clientModel = userInfo.clientModel;
          } else {
            this.employeeClientModel = true;
          }
          if (this.settings.clientModel) {
            this.settings.customerName = this.settings.clientModel.name;
            this.settings.customerCode = this.settings.clientModel.code;
            // if (!this.settings.customerOrderNo) {
            //   this.settings.customerOrderNo = this.expectCustomerOrderNo(this.selectedFile.name);
            //   this.checkDuplicateCustomerOrderNo(this.settings.customerOrderNo);
            // }
            this.customerReadonly = true;
            // client_id = this.settings.clientModel.id;
          } else {
            // this.employeeClientModel = true;
            this.customerReadonly = true;
          }

          // if (!client_id) {
          //   client_id = userInfo.userId;
          // }
        }
        // var access_token = localStorage.getItem('access_token');
        // console.log('client_id:' + client_id);
        // fetchReceiptChannel(access_token, client_id, '')
        //   .then(response => response.text())
        //   .then(result => this.suggestionChannels = JSON.parse(result).data)
        //   .catch(error => console.log('error', error));
      },
      closeDrawer() {
        if (!this.checkFileSettings(this.settings) && this.fileSettings.length
          && this.fileSettings[this.fileIndex].choosed
          && this.fileSettings[this.fileIndex].choosed === true) {
          this.settings.choosed = false;
          this.fileSettings[this.fileIndex].choosed = false;
        }
        this.show = false;
        this.selectedFile = null;
        this.fileIndex = null;
      },
      checkFileSettings(settings) {
        var checked = settings.clientModel !== undefined && settings.clientModel !== '{}'
                && settings.deliveryCountryModel !== undefined && settings.deliveryCountryModel !== '{}'
                && settings.customerName !== undefined && settings.customerName !== ''
                && settings.customerCode !== undefined && settings.customerCode !== ''
                && settings.customerOrderNo !== undefined && settings.customerOrderNo !== ''
                && settings.deliveryCountry !== undefined && settings.deliveryCountry !== ''
                && settings.shippingChannel !== undefined && settings.shippingChannel !== ''
                && settings.packageCount !== undefined && settings.packageCount > 0 && settings.packageCount !== '';
        return checked
      },
      async showAlertMsg(message) {
        alert(message);
      },
      triggerChangeCustomerOrderNo() {
        if (this.lastCustomerOrderNo === '' || this.lastCustomerOrderNo !== this.settings.customerOrderNo) {
          this.customerOrderNoChanged = true;
        }
      },
      foucosCustomerOrderNoInput() {
        var customerOrderNoInput = document.getElementById('customerOrderNo');
        if (customerOrderNoInput) {
          customerOrderNoInput.focus();
        }
      },
      checkDuplicateCustomerOrderNo(orderNo) {
        if (orderNo) {
          for(var i=0; i < this.files.length; i++) {
            var file = this.files[i];
            if (file.name === this.selectedFile.name) {
              continue;
            }
            if (file.boundCustomerOrderNo === orderNo && this.customerOrderNoChanged === true) {
              this.customerOrderNoChanged = false;
              this.lastCustomerOrderNo = orderNo;
              this.showAlertMsg('客户单号【' + orderNo + '】已绑定文件【' + file.name + '】，请核对后并修改正确！');
              // this.settings.customerOrderNo='';
              //this.foucosCustomerOrderNoInput();
              return true;
            }
          }
          this.checkExistsCustomerOrderNo(orderNo, this.settings.customerCode)
        }
        return false;
      },
      checkExistsCustomerOrderNo(orderNo, customerCode) {
        var exists = syncExistsWaybillOrderNoInCustomer(orderNo, customerCode);
        // 此处如果是异步调用的话，拿不到返回结果，所以暂时改为上面的同步调用
        // var exists = false;
        // existsWaybillOrderNo(orderNo)
        //   .then(response => response.text())
        //   .then(result => exists = JSON.parse(result).data)
        //   .catch(error => console.log('error', error));
        if (exists === false && this.customerOrderNoChanged === true) {
          this.customerOrderNoChanged = false;
          if (customerCode) {
            this.showAlertMsg('客户【' + customerCode + '】名下不存在客户单号【' + orderNo + '】，请核对后并修改正确！');
          } else {
            this.showAlertMsg('客户单号【' + orderNo + '】不存在，可能尚未预报，请核对后并修改正确！');
          }
          
          this.foucosCustomerOrderNoInput();
        }
        return exists;
      },
      getFile(index) {
        return this.files[index] || {};
      },
      saveSettings() {
        
        this.customerOrderNoChanged = true;
        console.log(JSON.stringify(this.settings));
        if (!this.settings.clientModel || this.settings.clientModel.id === undefined || this.settings.clientModel.id === '') {
          alert('请先选择客户');
          return;
        }
        //客户批量上传时，是否存在一个单号对应多个文件的情形
        if (this.checkDuplicateCustomerOrderNo(this.settings.customerOrderNo)) {
          return;
        }
        //客户批量上传时，是否存在一个单号对应多个文件的情形
        if (!this.checkExistsCustomerOrderNo(this.settings.customerOrderNo, this.settings.customerCode)) {
          return;
        }
        
        if (this.showCountriesSuggestions) {
          alert('【收货国家】提示列表请选中后,再保存！');
          return;
        }
        if (this.showChannelsSuggestions) {
          alert('【收货渠道】提示列表请选中后,再保存！');
          return;
        }
        
        var checked = this.checkFileSettings(this.settings);
        if (checked) {
          this.settings.employeeClientModel = this.employeeClientModel;
          if (this.fileSettings[this.fileIndex].choosed === false) {
            this.fileSettings[this.fileIndex].choosed = true;
          }
          this.settings.choosed = this.fileSettings[this.fileIndex].choosed;
          this.updateFileSettings({
            id: this.fileIndex,
            settings: this.settings,
          });
          var fileWrapper = this.getFile(this.fileIndex); 
          if (fileWrapper) {
            fileWrapper.boundCustomerOrderNo = this.settings.customerOrderNo;
          }
          this.closeDrawer();
        } else {
          alert('请检查必填项是否填写完整');
        }
      },
      getFileSettings(fileId) {
        // console.log('fileId:' + fileId);
        // console.log('files Settings 0:' + JSON.stringify(this.files));
        // console.log('files Settings:' + JSON.stringify(this.fileSettings));
        // const file = this.files.find(file => file.id === fileId);
        // return file ? file.settings : {};
        return this.fileSettings[fileId] || {};
      },
      // 这是我们将绑定到@input事件的方法
      debouncedFetchCustomersSuggestions() {
        // 清除之前的定时器
        clearTimeout(this.debounceTimeoutCustomers);
        // 设置一个新的定时器，当它超时后执行 fetchCountries方法
        this.debounceTimeoutCustomers = setTimeout(() => {
          this.fetchCustomers();
        }, 100); // 假设我们设置防抖时间为300毫秒
      },
      // 这是我们将绑定到@input事件的方法
      debouncedFetchCountriesSuggestions() {
        // 清除之前的定时器
        clearTimeout(this.debounceTimeoutCountries);
        // 设置一个新的定时器，当它超时后执行 fetchCountries方法
        this.debounceTimeoutCountries = setTimeout(() => {
          this.fetchCountries();
        }, 300); // 假设我们设置防抖时间为300毫秒
      },
       // 这是我们将绑定到@input事件的方法
       debouncedFetchChannelsSuggestions() {
        // 清除之前的定时器
        clearTimeout(this.debounceTimeoutChannels);
        // 设置一个新的定时器，当它超时后执行 fetchChannels方法
        this.debounceTimeoutChannels = setTimeout(() => {
          this.fetchChannels();
        }, 300); // 假设我们设置防抖时间为300毫秒
      },
      fetchCustomers() {
        // 客户名称输入框的值有输入时
        if (this.queryCustomer.length > 0) {
          this.showCustomersSuggestions = true;
          var access_token = localStorage.getItem('access_token');
          fetchAutoCompleteClient(access_token, this.queryCustomer)
            .then(response => response.text())
            .then(result => this.suggestionCustomers = JSON.parse(result).data)
            .catch(error => console.log('error', error));
        } else {
          // 输入长度清空时不发起请求        
          this.suggestionCustomers = [];
          this.showCustomersSuggestions = false;
        }
      }, 
      fetchCountries() {
        // 发货国家输入框的值有输入时
        if (this.settings.deliveryCountry.length > 0) {
          this.showCountriesSuggestions = true;
          var access_token = localStorage.getItem('access_token');
          fetchAutoCompleteContry(access_token, this.settings.deliveryCountry)
            .then(response => response.text())
            .then(result => this.suggestionCountries = JSON.parse(result).data)
            .catch(error => console.log('error', error));
        } else {
          // 输入长度清空时不发起请求        
          this.suggestionsCountries = [];
          this.showCountriesSuggestions = false;
        }
      }, 
      fetchChannels() {
        // 发货渠道输入框的值有输入时
        if (this.settings.shippingChannel.length > 0) {
          if (!this.settings.clientModel || this.settings.clientModel.id === undefined || this.settings.clientModel.id === '') {
            alert('请先选择客户');
            return;
          }
          this.showChannelsSuggestions = true;
          // console.log('fetchChannels: ' + this.settings.shippingChannel);
          var access_token = localStorage.getItem('access_token');
          fetchReceiptChannel(access_token, this.settings.clientModel.id, this.settings.shippingChannel)
            .then(response => response.text())
            .then(result => this.suggestionChannels = JSON.parse(result).data)
            .catch(error => console.log('error', error));
        } else {
          // 输入长度清空时不发起请求        
          this.suggestionsChannels = [];
          this.showChannelsSuggestions = false;
        }
      }, 
      hideCountriesSuggestions() {
        // 隐藏建议列表
        this.showCountriesSuggestions = false;
      },
      hideChannelsSuggestions() {
        // 隐藏建议列表
        this.showChannelsSuggestions = false;
      },
      selectCustomersSuggestion(suggestion) {
        // 当用户点击某个建议时，更新输入框的值
        this.settings.clientModel = suggestion;
        this.settings.customerName = suggestion.name;
        this.settings.customerCode = suggestion.code;

        // 清空建议列表
        this.clearQueryCustomer();
        // 触发检测客户单号是否重复
        if (this.settings.customerOrderNo) {
          this.customerOrderNoChanged = true;
          this.checkExistsCustomerOrderNo(this.settings.customerOrderNo, this.settings.customerCode);
        }
      },
      selectContriesSuggestion(suggestion) {
        // 当用户点击某个建议时，更新输入框的值
        this.settings.deliveryCountryModel = suggestion;
        this.settings.deliveryCountry = suggestion.nameCn + " - " + suggestion.nameEn; 
        // console.log('selectContriesSuggestion: ' + JSON.stringify(suggestion));
        // 清空建议列表
        this.suggestionCountries = [];
        this.showCountriesSuggestions = false;
      },
      selectChannelsSuggestion(suggestion) {
        // 当用户点击某个建议时，更新输入框的值  
        this.settings.shippingChannel = suggestion; 
        // console.log('selectChannelsSuggestion: ' + JSON.stringify(suggestion)); 
        // 清空建议列表
        this.suggestionChannels = [];
        this.showChannelsSuggestions = false;
      },
      clearQueryCustomer() {
        // 清空客户名称输入框的值
        this.queryCustomer = '';
        // 隐藏建议列表
        this.suggestionCustomers = [];
        this.showCustomersSuggestions = false;
      },
    },
  };
  </script>
  
<style scoped>
  .drawer {
    position: fixed;
    width: 350px;
    top: 0;
    right: 0;
    bottom: 0;
    /* left: 0; */
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }

  .drawer-content {
    position: absolute;
    width: 340px;
    height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }

  .form {
    margin-top: 50px;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 340px;
  }

  .form-row {
    display: flex;
    /* 使用 Flexbox 布局 */
    flex-direction: column;
    /* 垂直方向布局 */
    justify-content: center;
    /* 水平居中子元素 */
    align-items: center;
    /* 垂直居中子元素，如果需要的话 */
    flex-wrap: wrap;
    /* 允许子元素换行 */
    align-items: center;
    max-height: 600px;
    width: 100%;
    overflow-y: auto;
  }


  .form-row label {
    width: 100px;
    font-size: small;
    display: block;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .form-row select {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }

  .form-row input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    /* Ensure padding does not affect overall width */
  }

  .form-row button:disabled {
    background-color: #cccccc;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.6;
  }

  .form-row button {
    width: 80px;
    padding: 10px;
    margin-left: 30px;
    margin-top: 30px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  .form-row button:hover {
    background-color: #0056b3;
  }

  .required {
    color: red;
  }

  /** 自动补全输入框样式 **/
  .autocomplete-container {
    position: relative;
    width: 80%;
    /* height: 180px; */
    z-index: 1000;
    max-height: 180px;
    /* display: block; */
    /* 让下拉列表不换行 */
  }
 
  .autocomplete-container input {
    width: 100%;
    /* display: block; */
    /* 让下拉列表不换行 */
  }

  .autocomplete-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-left: 100px;
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
   
    /* 确保下拉列表在最上层 */
  }

  .autocomplete-container li {
    font-size: 12px;
    text-align: left;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #f9f9f9;
  }

  .autocomplete-container li:hover {
    background-color: #f1f1f1;
  }

  .autocomplete-container li.highlight {
    background-color: #e9e9e9;
  }

  @import url("@/styles/common.css");
</style>
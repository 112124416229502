<template>
  <div>
    <!-- 文件列表 -->
    <ul>
      <li v-for="(file, index) in files" :key="index">
        {{ file.name }}
        <!-- 点击按钮，展示该文件的详细属性面板 -->
        <button @click="showDrawer(index)">属性</button>
      </li>
    </ul>
    
    <!-- 文件属性的抽屉面板 -->
    <div v-if="currentFileIndex !== null" class="drawer">
      <!-- 假设每个文件都有一个与之关联的描述 -->
      <h3>{{ files[currentFileIndex].name }} 的属性</h3>
      <input type="text" v-model="fileDescriptions[currentFileIndex]" placeholder="添加描述">
      <button @click="currentFileIndex = null">完成</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: Array
  },
  data() {
    return {
      fileDescriptions: {}, // 这里可以存储文件的描述等属性
      currentFileIndex: null // 当前正在编辑的文件索引
    };
  },
  mounted() {
    // 初始化文件描述数组
    // console.log("传输过来的参数：" + this.files);
    
  },    
  methods: {
    showDrawer(index) {
      this.currentFileIndex = index;
    }
  }
};
</script>

<style>
/* 简单的抽屉样式 */
.drawer {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0,0,0,0.2);
  padding: 20px;
  /* ... 其他样式 */
}
</style>
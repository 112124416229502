<template>
  <div class="uprediction-success">
    <div class="success-message">
      <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
      <!-- <h2>客户单号 【 {{customerOrderNo}} 】 预报成功！</h2> -->
      <h2>预报成功</h2>
      <p>您的资料、文档已成功上传。</p >
      <button class="button-style" @click="navigateToHome">切换账户</button>
      <button class="button-style" @click="navigateToPrediction">继续预报</button>
      
    </div>
  </div>
</template>

<script>

export default {
  name: 'PredictionSuccess',
  computed: {    
    // 使用计算属性来获取查询参数 customerOrderNo    
    customerOrderNo() {      
      return this.$route.query.customerOrderNo;
    }
  },
  methods: {
    navigateToPrediction() {
      // this.$router.push({ name: 'prediction' }); // 假设你的首页路由名为 'prediction'
      this.$router.push({ name: 'portals' }); // 假设你的首页路由名为 'portals'
    },
    navigateToHome() {
      this.$router.push({ name: 'home' }); // 假设你的首页路由名为 'Home'
    },
  },
};
</script>

<style scoped>
.upload-success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.success-message {
  text-align: center;
}

.checkmark {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 6;
  stroke: #5cb85c;
  fill: none;
  animation: stroke 0.6s ease-in-out forwards;
}

.checkmark__check {
  transform-origin: left top;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s ease-in-out forwards;
  stroke: #fff;
  stroke-width: 2;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

/* 为按钮添加基础样式 */
.button-style {
  margin:10Px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* 鼠标悬停时的样式 */
.button-style:hover {
  background-color: #0056b3;
}

/* 鼠标按下时的样式 */
.button-style:active {
  background-color: #004080;
}

/* 按钮禁用时的样式 */
.button-style:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
</style>
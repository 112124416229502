export function uploadFile(file, url) {

    // 实现文件上传逻辑，可以使用axios或其他HTTP库
    var requestOptions = {
        method: 'PUT',
        headers: {"Content-Type":"application/octet-stream"},
        body: file,
        redirect: 'follow'
     };
     //console.log("上传路径:", url);
     return fetch(url, requestOptions)
        .then(response => response.text())
        // .then(result => {
        //     var data = result.data;
        //     // console.log(result);
        // })
        .catch(error => console.log('error', error));

}


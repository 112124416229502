<template>
  <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
  <h2>深圳亿达国际供应链</h2>
  <div>
    <FileUpload />
  </div>
</template>

<script>
import FileUpload from '@/components/domain/uploader/FileUploader.vue';

export default {
  components: {
    FileUpload
  }
};
</script>
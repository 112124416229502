// import Auth from "@/services/modules/Auth";

// auth.js
export const isAuthenticated = () => {
    // 这里应该是你的认证逻辑
    // 例如，检查本地存储中是否有token，或者调用API来验证用户会话
    // 返回true表示用户已登录，返回false表示用户未登录
    const access_token = localStorage.getItem('access_token');
    if (access_token !== null) {
        var requestOptions = {
            method: 'GET',
            headers: {Authorization: `Bearer ${access_token}`},
            redirect: 'follow'
         };
        return fetch("https://yida.itdida.com/itdida-api/flash/user/myself", requestOptions)
            .then(response => {      
                return response.json();
            })
            .then(data => {
                // 存储用户信息到本地存储
                const userJson = JSON.stringify(data.data, (key, value) => {
                    // 忽略permissionList字段
                    if (key === "permissionList") {
                      return undefined;
                    }
                    return value;
                  });
                localStorage.setItem('user_info', userJson);
                return data.success;
            })
            .catch(error => {
                console.error('认证检查失败:', error);
                return false; // 如果发生错误，假设用户未认证
            });
         
        // fetch("https://yida.itdida.com/itdida-api/flash/user/myself", requestOptions)
        //     .then(response => response.text())
        //     .then(result => {
        //         if (result) {
        //             // 解析 JSON 字符串
        //             var jsonResult = JSON.parse(result);
        //             authenticated = jsonResult.success;                  
        //         } else {
        //             authenticated =  false;
        //         }
                
        //     })
        //     .catch(error => {
        //         console.log('error', error);
        //         authenticated = false;
        //     });
   
    }
    return false;
  };
<template>
  <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
  <h2>深圳亿达国际供应链</h2>
  <div>
    <div class="portal-form">
      <div class="form-row">
        <button @click="choosePortal()" :disabled="!employeeClientModel">发票预报</button>
      </div>
      <div class="form-row">
        <button  @click="choosePortal2()" :disabled="!employeeClientModel">补充发票</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        employeeClientModel: false,      // 是否是员工端登录
      };
    },
    methods: {
      getUserInfo() {
        var userInfo = JSON.parse(localStorage.getItem('user_info'));
        return userInfo;
      },
      isEmployeeClient() {
        var userInfo = this.getUserInfo();
        if (userInfo) {
          this.employeeClientModel = !userInfo.clientModel;
          return this.employeeClientModel;
        }
        return false;
      },
      choosePortal() {
        this.$router.push({ name: 'selection', params: { files: [] } });
      },
      choosePortal2() {
        this.$router.push({ name: 'selection2', params: { files: [] } });
      },

    },
    // 页面加载完成后执行
    mounted() {
      console.log('MultiPortalView mounted');
      // 这里可以执行DOM渲染完成后的代码
      if (this.isEmployeeClient()) {
        // 员工端登录
        console.log('员工端登录，等待选择');
      } else {
        // 客户端登录}
        console.log('客户端登录, 自动跳转路由');
        this.$router.push('/selection');
      }
    }
  };
</script>

<style scoped>
  .portal-form {
    max-width: 350px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .form-row {
    margin-bottom: 10px;
  }


  .form-row button:disabled {
    background-color: #cccccc;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.6;
  }

  .form-row button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  .form-row button:hover {
    background-color: #0056b3;
  }

  .form-row button:hover {
    background-color: #0056b3;
  }

  @import url("@/styles/common.css");
</style>
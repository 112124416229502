<template>
  <form class="submit-form">
    <!-- <div class="form-row">
      <div class="form-group">
        <label for="customerOrderNo"><span class="required">*</span></label>
        <input type="text" id="customerOrderNo" v-model="customerOrderNo" placeholder="客户单号" />
      </div>
    </div> -->
    <div class="form-row">
        <label for="invoce" align="left"><span class="file-upload-label">请上传发票（注意：文件名需包含您定义的客户单号）</span></label>
        <div class="form-group">
          <label for="invoice"><span class="required">*</span></label>
          <input type="file" id="invoce" @change="handleFilesUpload" multiple/> 
        </div>
    </div>
    <div class="form-row">
      <button type="button" @click="submitForm" :disabled="!isFormValid">开始预报</button>
    </div>
  </form>
  
</template>

<script>
  

import { uploadFile } from '@/utils/FileUpload.js';
// import { FILE_SERVER_URL, UPLOAD_ROOT_DIR} from '@/Constants.js';
//import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      customerOrderNo: '',
      selectedCountry: null,
      countries: ['Afghanistan', 'Albania', 'Algeria', 'Andorra', /* ... 其他国家 ... */ 'Zambia', 'Zimbabwe'],
      selectedChannel: null,
      channels: ['DHL', 'Fediex', 'UPS'],
      count: 1, // 默认值，确保至少为1
      files: null, // 用于存储选中的文件
    };
  },
  created() {
    this.fetchContries(); // 在组件创建时获取国家
  },
  computed: {
    isFormValid() {
      return  /** this.customerOrderNo.trim() !== '' && */ this.files !== null;
    }
  },
    
  methods: {
    handleFilesUpload(event) {
      //this.file = event.target.files[0];
      this.files = event.target.files;
    },

    submitForm() {
      if (this.isFormValid) {
        // 如果所有必填字段都有效，执行提交逻辑
        // 例如，调用上传文件的方法或者发送数据到服务器
        this.triggerUploadFiles();
        // ... 其他提交逻辑 ...
        // 创建预报单
        this.createPreorder();
        //跳转到预报成功页面
        this.$router.push({ name: 'prediction-success', query: { customerOrderNo: this.customerOrderNo } });
        
      } else {
        // 如果必填字段为空，显示错误信息或者执行其他逻辑
        alert('请填写所有必填项。');
      }
    },
    triggerUploadFile(file) {
      // 上传发票 
      if (file) {
        // const baseUrl = FILE_SERVER_URL + UPLOAD_ROOT_DIR; // 假设上传文件接口地址
        const baseUrl = ""; // 假设上传文件接口地址
        var newFileName = file.name; // 重命名文件名 
        if (!file.name.includes(this.customerOrderNo)) {
          newFileName = `${this.customerOrderNo}-${file.name}`; // 重命名文件名
        }
        var userinfo = localStorage.getItem('user_info');
        if (userinfo) {  
          try {
            newFileName = `${JSON.parse(userinfo).fullName}-${newFileName}`; 
          } catch (e) {
            console.error(e);
          }
        } 
        
        uploadFile(file, baseUrl +"/" + encodeURIComponent(newFileName));
        
      }
    },
    triggerUploadFiles() {  
      // 上传多个文件 
      if (this.files) { 
        for (var i = 0; i < this.files.length; i++) { 
          const file = this.files[i]; 
          this.triggerUploadFile(file);
        }
      }
    },
    async fetchContries() {
      try {
        // const response = await axios.get('你的API接口URL');
        // const response ="{ data: [{ value: 'value1', label: 'label1' }}";
        //this.countries = response.data; // 假设API返回的数据格式为{ data: [{ value: 'value1', label: 'label1' }, ...] }
      } catch (error) {
        console.error('获取国家失败:', error);
        // 处理错误情况
      }
    },
    async createPreorder() {
      // 创建预报单
      //console.log('创建预报单');
    }
  },
  watch: {
    selectedInvoiceType(newValue) {
      // 当选中的值改变时触发
      console.log('选中的值:', newValue);
    }
  }
};



</script>



<style scoped>

.submit-form {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-row {
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-row label {
  display: block;
  margin-bottom: 5px;
  margin-right: 5px;
}

.form-row input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding does not affect overall width */
}

.form-row button:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.6;
}
.form-row button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.form-row button:hover {
  background-color: #0056b3;
}

.required {
  color: red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  display: block;
  margin-top: 5px;
}

.file-upload-label {
  color: red;
  font-size: 12px; /* 设置你想要的字体大小 */
}
@import url("@/styles/common.css");


</style>
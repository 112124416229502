<template>
  <form class="login-form" @submit.prevent="handleLogin">
    <div class="form-row">
      <input type="text" id="username" v-model="username" placeholder="用户名" ref="usernameInput"/>
    </div>
    <div class="form-row">
      <input type="password" id="password" v-model="password" placeholder="密码" ref="passwordInput"/>
    </div>
    <div class="form-row">
      <button type="submit" :disabled="!isFormValid">登录</button>
    </div>
  </form>
</template>

<script>
//import proxy from '@/utils/proxy';
export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },

  computed: {
    isFormValid() {
          return this.username.trim() !== '' && this.password.trim() !== '';
    }
  },
  

  methods: {
    proxyLogin(username, password) {
      var urlencoded = new URLSearchParams();
      // 调用登录接口
      // 登录成功后，跳转到相应页面
      // 登录失败后，显示错误信息 或 弹出错误提示
      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/xx-www-form-urlencoded' },
        body: urlencoded,
        redirect: 'follow'
      };
      var access_token = "";
      fetch("https://yida.itdida.com/itdida-api/login?username="+ username + "&password=" + password, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result) {
              // 解析 JSON 字符串
              if (result.success) {
                access_token = result.data;
                localStorage.setItem("access_token", access_token);
                //this.$router.push('/prediction');
                this.$router.push('/portals');
              } else {  
                alert("登录失败，请检查用户名或密码");  
              }
              
            } else {
              alert("登录失败，请检查用户名或密码");
            }
        })
        .catch(error => console.log('error', error));
      
    },

    handleLogin() {
      // 登录逻辑
      if (this.isFormValid) {
        // 调用登录接口
        this.proxyLogin(this.username, this.password);
      }
      
    },
  }
};
</script>

<style scoped>
.login-form {
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-row {
  margin-bottom: 10px;
}

.form-row label {
  display: block;
  margin-bottom: 5px;
}

.form-row input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding does not affect overall width */
}

.form-row button:disabled {
  background-color: #cccccc;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.6;
}
.form-row button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.form-row button:hover {
  background-color: #0056b3;
}

.form-row button:hover {
  background-color: #0056b3;
}
@import url("@/styles/common.css");
</style>
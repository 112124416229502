<template>
  <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
  <h2>深圳亿达国际供应链</h2>
  <div class="form-row">
    <div style="align-content: center; ">
      <label style="margin-top: 10px; margin-bottom: 28px; font-size: 20px; font-weight: bold;">
        <span class="file-upload-label">*</span>发票预报
      </label>
    </div>
    <div style="margin-top: 20px; ">
      <input type="file" multiple @change="handleFiles" style="margin-left: 10px;" />
    </div>
    <label for="invoce"><span class="file-upload-label">注意：文件名需包含您定义的客户单号</span></label>
    <div class="file-list-container" v-if="selectedFiles.length">
      <label style="font-size: 14px; font-weight: bold;color: #095442;">【勾选文件，填写预报信息】</label>
      <ul class="file-list" v-if="selectedFiles.length">
        <li v-for="(file, index) in selectedFiles" :key="index" class="file-item" @click="openDrawer(file, index)" 
          :hidden="hiddenFiles.includes(index)">
          <!-- <input class="file-checkbox" type="checkbox" :id="index" :value="fileSettings[index].choosed" @change="toggleCheckbox(file, index)" /> -->
          <label class="custom-checkbox">
            <input type="checkbox" :checked="fileSettings[index].choosed" @change="toggleCheckbox(file, index)" />
            <span class="checkmark"></span>
          </label>
          <span class="file-name">{{ file.name }}</span>
          <span class="file-size">【{{ formatSize(file.size) }}】</span>
          <!-- 添加使用 "X" 图标的删除按钮 -->
          <button @click.stop="deleteFile(index)" class="delete-button">&times;</button>
        </li>
      </ul>
      <!-- 抽屉组件 -->
      <drawer-component ref="drawerRef"></drawer-component>
    </div>
    <div>
      <!-- <button class="next-step-button" style="margin-right: 20px;" disabled="true">上一步</button> -->
      <button id="goToUploadList" @click="triggerUploadFilesV2" class="next-step-button" :disabled="loading">开始预报</button>
    </div>
  </div>
  <!-- 雪花加载效果 -->
  <!-- <Loading v-if="loading" type="spinner" /> -->
  <!-- <VueLoading v-if="loading" type="spinner" /> -->
  <div v-if="loading" style="width: 100%;">
    <div class="snowflake-loading"></div>
    <div style="color: #3498db; font-size: 12px; text-align: center;">正在上传</div>
  </div>
</template>

<script>
  import DrawerComponent from '@/components/common/DrawerModal.vue';
  import { mapState, mapActions } from 'vuex';
  // import { FILE_SERVER_URL, UPLOAD_ROOT_DIR } from '@/Constants.js';
  import { createWaybillOrderV2, uploadCommercialInvoiceFiles, asyncUploadCommercialInvoiceFiles } from '@/components/domain/apis/ProxyItdida.js';
  // import { uploadFile } from '@/utils/FileUpload.js';

  export default {
    data() {
      return {
        selectedFiles: [],
        uploadFiles:[],
        hiddenFiles: [],
        loading: false,
      };
    },
    components: {
      DrawerComponent
    },
    computed: {
      ...mapState(['files', 'fileSettings']),
    },
    methods: {
      ...mapActions(['addFile', /**'openDrawer',*/ 'closeDrawer', 'resetFiles', 'resetFileSettings']),
      getFile(fileIndex) {
        return this.files[fileIndex];
      },
      getFileSettings(fileIndex) {
        return this.fileSettings[fileIndex];
      },
      checkFileSettings(settings) {
        var checked = settings.clientModel !== undefined && settings.clientModel !== '{}'
          && settings.deliveryCountryModel !== undefined && settings.deliveryCountryModel !== '{}'
          && settings.customerName !== undefined && settings.customerName !== ''
          && settings.customerCode !== undefined && settings.customerCode !== ''
          && settings.customerOrderNo !== undefined && settings.customerOrderNo !== ''
          && settings.deliveryCountry !== undefined && settings.deliveryCountry !== ''
          && settings.shippingChannel !== undefined && settings.shippingChannel !== ''
          && settings.packageCount !== undefined && settings.packageCount > 0 && settings.packageCount !== '';
        return checked
      },
      choosedFile(index) {
        // 假设你有一个要选中的文件索引
        this.fileSettings[index].choosed = true;
      },
      deleteFile(index) {
        // 假设你有一个要删除的文件索引
        if (this.$refs.drawerRef && this.$refs.drawerRef.show && index === this.$refs.drawerRef.fileIndex) {
          this.$refs.drawerRef.closeDrawer();
        }
        this.fileSettings[index].choosed = false;
        this.hiddenFiles.push(index);
      },
      unChoosedCheckedFailedFiles(index) {
        for (var i = 0; i < this.selectedFiles.length; i++) {
          if (i === index) {
            continue;
          }
          if (this.fileSettings[i].choosed) {
            var checked = this.checkFileSettings(this.fileSettings[i]);
            if (!checked) {
              this.fileSettings[i].choosed = false;
            }
          }
        }
      },
      openDrawer(file, index) {
        // 循环文件列表，是否存在检查不合格的文件
        this.unChoosedCheckedFailedFiles(index);
        // 打开抽屉组件
        this.$refs.drawerRef.openDrawer(file, index);
      },
      toggleCheckbox(file, index) {
        // 循环文件列表，是否存在检查不合格的文件
        this.unChoosedCheckedFailedFiles(index);
        const fileSettings = this.getFileSettings(index);
        fileSettings.choosed = !fileSettings.choosed;
      },
      handleFiles(event) {
        const files = Array.from(event.target.files);
        this.resetFiles();
        this.resetFileSettings();

        if (this.$refs.drawerRef) {
          this.$refs.drawerRef.closeDrawer();
        }
        //遍历文件，判断文件大小是否超过20MB
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size > 1024 * 1024 * 20) { // 20MB
            alert(`文件 ${file.name} 大小超过20MB，请重新选择.`);
            files.pop();
            event.target.value = null;
            this.selectedFiles = [];
            return;
          }

          // 将 file 和 idx 封装成一个对象作为 payload 传递给 action
          this.addFile({
            file: file,
            index: i
          });
        }
        // 文件列表都符合大小限制要求，则将文件列表保存到 selectedFiles 中
        this.selectedFiles = files;
        // 如果选择文件不为空，则调用 openDrawer 方法打开第一个文件的抽屉组件
        if (this.selectedFiles.length) {
          // 非阻塞延时2秒后调用
          setTimeout(() => {
            this.openDrawer(this.selectedFiles[0], 0);
            this.choosedFile(0);
          }, 1000);
          
        }
      },
      getUserInfo() {
        var userinfo = localStorage.getItem('user_info');
        if (userinfo) {
          try {
            return JSON.parse(userinfo);
          } catch (e) {
            console.error(e);
            return null;
          }
        }
      },
      formatSize(sizeInBytes) {
        if (sizeInBytes === 0) return '0 Byte';
        const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let unitIndex = 0;
        while (sizeInBytes >= 1024) {
          sizeInBytes /= 1024;
          unitIndex++;
        }
        return `${(sizeInBytes.toFixed(unitIndex === 0 ? 0 : 2))} ${units[unitIndex]}`;
      },
      async createPredictionBillwayOrderV2(array) {
        var success = false;
        await createWaybillOrderV2(array)
          .then(response => response.text())
          .then(result => {
            var resultObj = JSON.parse(result);
            success = resultObj.code === 0;
          })
          .catch(error => console.log('error', error));
        return success;
      },
      async createPredictionBillwayOrderV3(array, callbacks) {
        var success = false;
        createWaybillOrderV2(array)
          .then(response => response.text())
          .then(result => {
            var resultObj = JSON.parse(result);
            success = resultObj.code === 0;
            if (success) {
              callbacks.onSuccess();
            } else {
              callbacks.onFailed();
            }
          })
          .catch(error => {
            console.log('error', error);
            callbacks.onError();}
          );      
      },
      triggerUploadFile(index, file, fileSettings) {
        // 上传发票 
        if (file) {
          // const baseUrl = FILE_SERVER_URL + UPLOAD_ROOT_DIR; // 假设上传文件接口地址
          const baseUrl = ""; // 假设上传文件接口地址
          var newFileName = file.name; // 重命名文件名
          if (fileSettings && fileSettings.customerOrderNo) {
            if (!file.name.includes(fileSettings.customerOrderNo)) {
              newFileName = `${fileSettings.customerOrderNo}-${file.name}`; // 重命名文件名
            }
          }
          var userinfo = localStorage.getItem('user_info');
          if (userinfo) {
            try {
              var userJson = JSON.parse(userinfo);
              // 如果文件名不是以用户姓名打头，则在文件前面追加用户姓名
              if (!newFileName.includes(userJson.fullName)) {
                 newFileName = `${userJson.fullName}-${newFileName}`;
              }
            } catch (e) {
              console.error(e);
            }
          }
          var encodedFileName = encodeURIComponent(newFileName);
          var fileUrl = baseUrl + "/" + encodedFileName;
          // TODO 考虑文件上传失败的情况
          //var result = await uploadCommercialInvoiceFile(file, newFileName);
          var result = true;
          this.uploadFiles.push({
            "index": index,
            "file": file,
            "newFileName": newFileName
          });
          //uploadFile(file, fileUrl);
          var fileWrapper = this.getFile(index);
          if (fileWrapper) {
            fileWrapper.uploaded = result === true ? 1 : 0;
            fileWrapper.url = fileUrl;
            fileWrapper.newFileName = newFileName;
            fileWrapper.serviceBaseUrl = baseUrl;
          }

        }
      },
      async triggerBackgroundTask(i) {
        // 后台任务
        const file = this.selectedFiles[i];
        // 创建预报价记录
        var fileSettings = this.getFileSettings(i);
        // var fileWrapper = this.getFile(i);
        // 上传文件
        await this.triggerUploadFile(i, file, fileSettings);
        
      },
      async triggerUploadFilesV2() {
        // 将选中的文件作为参数传递到下一个路由
        if (this.selectedFiles.length === 0) {
          alert("请选择文件");
          return;
        }
        if (this.$refs.drawerRef && this.$refs.drawerRef.show) {
          alert("请先【保存】或【取消】当前预报信息");
          return;
        }
        // 预报前进行数据校验
        var hasError = false;
        var choosedFileSettings = [];
        for (var i = 0; i < this.fileSettings.length; i++) {
          var fileSetting = this.fileSettings[i];
          // 过滤掉客户未勾选中的文件
          if (!fileSetting.choosed) {
            continue;
          }
          if (this.checkFileSettings(fileSetting) === false) {
            hasError = true;
          }
          choosedFileSettings.push(fileSetting);
        }
        if (choosedFileSettings.length === 0) {
          alert("请至少勾选一个文件，并完整填写预报信息");
          return;
        }
        if (hasError) {
          alert("存在填写不完全的预报记录");
          return;
        }
        // 上传多个文件 
        if (this.selectedFiles) {
          var access_token = localStorage.getItem('access_token');
          var array = [];
          var failedArray = [];
          // 弹出loading窗口
          this.loading = true;
          this.uploadFiles = [];
          for (var j = 0; j < this.selectedFiles.length; j++) {
            //过滤掉客户未勾选中的文件
            if (!this.getFileSettings(j).choosed) {
              continue;
            }
            await this.triggerBackgroundTask(j);
          }
          // console.log("批量上传 uploadFiles: " + JSON.stringify(this.uploadFiles));
          asyncUploadCommercialInvoiceFiles(this.uploadFiles)
            .then(response => response.text())
            .then(res => {
              var resultObj = JSON.parse(res);
              var uploadRes = resultObj.data;
              
              this.loading = false;
              const hasFalse = uploadRes.includes(false);
              if (hasFalse) {
                console.error("上传失败");
                alert("存在文件上传失败");
                return;
              }
              var userInfo = this.getUserInfo();
              for (var k = 0; k < this.uploadFiles.length; k++) {
                // 获取文件索引
                var index = this.uploadFiles[k].index;
                var fileWrapper = this.getFile(index);
                this.fileSettings[index].commercialInvoiceUrl = this.getFile(index).url;
                this.fileSettings[index].accessToken = access_token;
                // TODO 这里要考虑上传失败的情况
                if (fileWrapper.uploaded === 1 && uploadRes[k] === true) {
                  //只有填写了上报信息的情况下才进行上报
                  if (this.fileSettings[index].customerOrderNo !== undefined && this.fileSettings[index].customerOrderNo !== '') {
                    if (this.fileSettings[index].employeeClientModel !== undefined && this.fileSettings[index].employeeClientModel === true) {
                      var userName = userInfo ? userInfo.fullName : "未知";
                      // 预报备注
                      this.fileSettings[index].predictNote = "由员工【" + userName + "】预报";
                    } else {
                      this.fileSettings[index].predictNote = "由客户【" + this.fileSettings[index].customerName + "】预报";
                    }
                    array.push(this.fileSettings[index]);
                  }
                } else {
                  failedArray.push(fileWrapper);
                }
              }

              // 存在预报记录，调用批量接口创建预报价记录
              if (array.length > 0) {
                this.createPredictionBillwayOrderV3(array,  {
                  onSuccess: () => {
                    // 通过路由的meta参数传递文件列表
                    this.$nextTick(() => {
                      this.$router.push({ name: 'prediction-success', params: { files: this.selectedFiles } });
                    });
                  },
                  onFailed: () => {
                    console.log("预报失败");
                    alert("预报失败");
                  },
                  onError: () => {
                    console.log("预报异常");
                    alert("预报异常");
                  }
                });
                
                return;
              } else {
                // 通过路由的meta参数传递文件列表
                this.$nextTick(() => {
                      this.$router.push({ name: 'prediction-success', params: { files: this.selectedFiles } });
                    });
              }
            })
            .catch(error => console.log('error', error));

        }
      },
      async triggerUploadFiles() {
        // 上传多个文件 
        if (this.selectedFiles) {
          var access_token = localStorage.getItem('access_token');
          var array = [];
          var failedArray = [];
          // 弹出loading窗口
          this.loading = true;
          for (var j = 0; j < this.selectedFiles.length; j++) {
            await this.triggerBackgroundTask(j);
          }
          
          // 调用批量接口创建预报价记录
          // const array = Array.from(this.fileSettings.values());
          // console.log("批量创建预报价 JSON: " + JSON.stringify(array));
          // 批量上传
          var uploadRes = await uploadCommercialInvoiceFiles(this.uploadFiles);
          // var uploadRes = [];
          // for (var x = 0; x < this.selectedFiles.length; x++) {
          //   uploadRes.push(true);
          // }
          // console.log("批量上传结果: " + JSON.stringify(uploadRes));
          // uploadeRes为上传结果列表
          this.loading = false;
          const hasFalse = uploadRes.includes(false);
          if (hasFalse) {
            console.error("上传失败");
            alert("存在文件上传失败");
            return false;
          }
          var userInfo = this.getUserInfo();
          for (var i = 0; i < uploadRes.length; i++) {
            var fileWrapper = this.getFile(i);
            this.fileSettings[i].commercialInvoiceUrl = this.getFile(i).url;
            this.fileSettings[i].accessToken = access_token;
            // TODO 这里要考虑上传失败的情况
            if (fileWrapper.uploaded === 1 && uploadRes[i] === true) {
              //只有填写了上报信息的情况下才进行上报
              if (this.fileSettings[i].customerOrderNo !== undefined && this.fileSettings[i].customerOrderNo !== '') {
                if (this.fileSettings[i].employeeClientModel !== undefined && this.fileSettings[i].employeeClientModel === true) {
                  var userName = userInfo ? userInfo.fullName : "未知";
                  this.fileSettings[i].predictNote = "由员工【" + userName + "】预报";
                } else {
                  this.fileSettings[i].predictNote = "由客户【" + this.fileSettings[i].customerName + "】预报";
                }
                array.push(this.fileSettings[i]);
              }
            } else {
              failedArray.push(fileWrapper);
            }
          }

          // 存在预报记录，调用批量接口创建预报价记录
          if (array.length > 0) {
            var result = await this.createPredictionBillwayOrderV2(array);
            if (result === false) {
              console.error("预报失败:" + result);
              alert("预报失败");
            }
            return result;
          }
        }
        return true;
      },

      async goToUploadList() {
        // 将选中的文件作为参数传递到下一个路由
        if (this.selectedFiles.length === 0) {
          alert("请选择文件");
          return;
        }
        // 上传文件
        var res = await this.triggerUploadFiles();
        if (res === true) {
          // 通过路由的meta参数传递文件列表
          this.$router.push({ name: 'prediction-success', params: { files: this.selectedFiles } });
        }
      },
    }

  };
</script>

<style scoped>
  .form-row {
    display: flex;
    /* 使用 Flexbox 布局 */
    flex-direction: column;
    /* 垂直方向布局 */
    justify-content: center;
    /* 水平居中子元素 */
    align-items: center;
    /* 垂直居中子元素，如果需要的话 */
    flex-wrap: wrap;
    /* 允许子元素换行 */
    margin-top: 50px;
    /* 添加一些上边距，看起来更美观 */
    align-items: center;
    max-height: 500px;
    overflow-y: auto;

  }

  .file-upload-label {
    color: red;
    font-size: 12px;
    text-align: left;
    /* 设置你想要的字体大小 */
    margin-bottom: 20px;
  }

  .form-row {
    margin-bottom: 10px;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .form-row label {
    display: block;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .form-row input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    /* Ensure padding does not affect overall width */
  }

  .next-step-button {
    margin-top: 20px;
    padding: 10px 15px;
    /* 按钮内边距 */
    background-color: #3498db;
    /* 按钮背景色 */
    color: #ffffff;
    /* 按钮文字颜色 */
    border: none;
    /* 不显示边框 */
    border-radius: 5px;
    /* 边角弧度 */
    cursor: pointer;
    /* 鼠标悬停时的指针样式 */
    font-size: 16px;
    /* 字体大小 */
    transition: background-color 0.3s ease;
    /* 背景色变化过渡动画 */
  }

  .next-step-button:hover {
    background-color: #2874a6;
    /* 鼠标悬停时按钮的背景色变化 */
  }

  .next-step-button:disabled {
    background-color: #565353;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.8;
  }

  /* 如果有需要，也可以给文件input添加一些样式 */
  input[type="file"] {
    margin-bottom: 10px;
    /* 与按钮的间隔 */
  }

  .file-list-container {
    margin-left: 20px;
    max-width: 320px;
    min-width: 320px;
    max-height: 180px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    list-style-type: none;
    margin-top: 20px;
  }

  .file-list {
    list-style-type: none; /* 移除列表项前的默认符号 */
    padding: 0; /* 移除默认的内边距 */
    margin: 0; /* 移除默认的外边距 */
  }

  .file-checkbox {
    margin: 0 !important; /* 移除外边距 */
    padding: 0; /* 如果需要，也可以移除内边距 */
    width: 20px;
  }

  .file-item {
    display: flex;
    text-align: left;
    align-items: bottom;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
  }

  .file-item[hidden] {
    display: none;
  }

  .custom-checkbox {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
  border-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

  .file-name {
    width: 200px;
    max-width: 200px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-size {
    width: 68px;
    font-size: 10px;
    color: #666;
  }

  .snowflake-loading {
    width: 40px;
    height: 40px;
    align-items: center;
    margin: 0 auto;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .delete-button {
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    font-size: 13px;
    padding: 0px;
    cursor: pointer;
  }
</style>
// import Vue from 'vue';
// import VueRouter from 'vue-router';
import { createWebHistory, createRouter } from 'vue-router'
import LoginView from '@/components/LoginView.vue';
import UploadView from '@/components/UploadView.vue';
import MultiPortalView from '@/components/MultiPortalView.vue';
import FileSelectionView from '@/components/FileSelectionView.vue';
import FileSelectionView2 from '@/components/FileSelectionView2.vue';
import UploadListView from '@/components/UploadListView.vue';
import PredictionSuccess from '@/components/PredictionSuccess.vue';
import { isAuthenticated } from '@/utils/auth.js';


// Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/prediction',
    name: 'prediction',
    component: UploadView,
    meta: { 
      requiresAuth: true, // 标记需要登录的路由
    }
  },
  {
    path: '/portals',
    name: 'portals',
    component: MultiPortalView,
    meta: { 
      requiresAuth: true, // 标记需要登录的路由
    }
  },
  {
    path: '/selection2',
    name: 'selection2',
    component: FileSelectionView2,
    meta: { 
      requiresAuth: true, // 标记需要登录的路由
      selectedFiles: [] // 传递文件列表
    }
  },
  {
    path: '/selection',
    name: 'selection',
    component: FileSelectionView,
    meta: { 
      requiresAuth: true, // 标记需要登录的路由
      selectedFiles: [] // 传递文件列表
    }
  },
  {
    path: '/predictions',
    name: 'predictions',
    component: UploadListView,
    props: true, // 允许组件通过props接收到路由参数
    meta: { 
      requiresAuth: true, // 标记需要登录的路由
    }
  },
  {
    path: '/prediction-success',
    name: 'prediction-success',
    component: PredictionSuccess,
    props: true // 确保组件可以接受 props
    // meta: { requiresAuth: true }  // 标记需要登录的路由
  }
];

const router = createRouter({
  // history: createMemoryHistory(),
  // base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL), // 使用createWebHistory
  routes,
});



// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    isAuthenticated().then(isAuth => {
      if (isAuth) {
        next(); // 用户已认证，允许访问
      } else {
        next({ path: '/login' }); // 用户未认证，重定向到登录页面
      }
    });
  } else {
    next(); // 路由不需要认证，继续导航
  }
});


export default router;
<template>
  <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
  <h2>深圳亿达国际供应链</h2>
  <div>
    <FilesUpload :files="files" />
  </div>
</template>

<script>
import FilesUpload from '@/components/domain/uploader/FilesUploader.vue';


export default {
  props: {
    files: Array
  },
  mounted() { 
    console.log(this.files);
    console.log(this.$route.meta.selectedFiles);
    //this.$refs.filesUploader.setFiles(this.files);
  }, 
  components: {
    FilesUpload
  }
};
</script>
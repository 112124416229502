import { YIDA_BACKEND_API_URL, YIDA_PROXY_API_BASE_URL } from '@/Constants.js';

/**
 * 登录获取 access token
 * @param {*} username 
 * @param {*} password 
 * @returns 
 */
export const fetchAccessToken = (username, password) => {
    var urlencoded = new URLSearchParams();
    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/xx-www-form-urlencoded' },
        body: urlencoded,
        redirect: 'follow'
    };
    return fetch(YIDA_PROXY_API_BASE_URL + "/itdida-api/login?username="+ username + "&password=" + password, requestOptions);
}

/**
 * 获取当前登录人信息
 * @param {*} access_token 
 * @returns 
 */
export const fetchMyself = (access_token) => {
    var requestOptions = {
        method: 'GET',
        headers: {Authorization: `Bearer ${access_token}`},
        redirect: 'follow'
     };
    return fetch(YIDA_PROXY_API_BASE_URL + "/itdida-api/flash/user/myself", requestOptions);         
}

/**
 * 根据关键字搜索发货送达国家
 * @param {*} access_token 
 * @param {*} keyword 
 * @returns 
 */
export const fetchAutoCompleteContry = async (access_token, keyword) => {
    var raw = JSON.stringify({
        "query": keyword
     });
     
     var requestOptions = {
        method: 'POST',
        headers: {Authorization: `Bearer ${access_token}`},
        body: raw,
        redirect: 'follow'
     };
     
    return fetch(YIDA_PROXY_API_BASE_URL + "/itdida-api/flash/autocomplete/country", requestOptions);
}

/**
 * 根据关键字搜索客户
 * @param {*} access_token 
 * @param {*} keyword 
 * @returns 
 */
export const fetchAutoCompleteClient = async (access_token, keyword) => {
    var raw = JSON.stringify({
        "query": keyword
     });
     
     var requestOptions = {
        method: 'POST',
        headers: {Authorization: `Bearer ${access_token}`},
        body: raw,
        redirect: 'follow'
     };
     
    return fetch(YIDA_PROXY_API_BASE_URL + "/itdida-api/flash/autocomplete/client", requestOptions);
}

/**
 * 
 * @param {获取发货渠道} access_token 
 * @param {*} client_id 
 * @param {*} keyword 
 * @returns 
 */
export const fetchReceiptChannel = async (access_token, client_id, keyword) => {
    var query = {
        "query": keyword,
     };
    var raw = JSON.stringify(query);

    var requestOptions = {
        method: 'POST',
        headers: {Authorization: `Bearer ${access_token}`},
        body: raw,
        redirect: 'follow'
    };

    return fetch(YIDA_PROXY_API_BASE_URL + "/itdida-api/flash/autocomplete/receiptChannelName/clientId/" + client_id + "/logisticsMode/0", requestOptions);
}

export const createWaybillOrderV2 = (/**access_token,*/ file_settings_array) => {
    // 设置请求头
    var myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(file_settings_array);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
     };
     
    return fetch(YIDA_BACKEND_API_URL + "/yida/waybill-prediction/batchCreateSimple", requestOptions);
}

export const existsWaybillOrderNo = (/**access_token,*/ waybillOrderNo) => {
    return existsWaybillOrderNoInCustomer(waybillOrderNo, null);
}
export const existsWaybillOrderNoInCustomer = (/**access_token,*/ waybillOrderNo, customerCode) => {
    // 设置请求头
    var myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Content-Type", "application/json");

    var requestJson = {
        "customerOrderNo": waybillOrderNo,
        "customerCode": customerCode 
    };
    var raw = JSON.stringify(requestJson);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
     };
     
    return fetch(YIDA_BACKEND_API_URL + "/yida/waybill-prediction/existsWaybillNo", requestOptions);
}
export const syncExistsWaybillOrderNo =  (/**access_token,*/ waybillOrderNo) => {
    return syncExistsWaybillOrderNoInCustomer(waybillOrderNo, null);
}
export const syncExistsWaybillOrderNoInCustomer =  (/**access_token,*/ waybillOrderNo, customerCode) => {
    var requestJson = {
        "customerOrderNo": waybillOrderNo,
        "customerCode": customerCode 
    };
    var raw = JSON.stringify(requestJson);
    
    var request = new XMLHttpRequest();
    // 使用 'false' 使得请求为同步
    request.open('POST', YIDA_BACKEND_API_URL + "/yida/waybill-prediction/existsWaybillNo", false);
    request.setRequestHeader("Content-Type", "application/json");
    
    request.send(raw);
    
    // 获取响应或处理错误
    if(request.status === 200) {
        return JSON.parse(request.responseText).data;
    } else {
        console.error("Error occurred:", request.statusText);
        return false;
    }
}

export const uploadCommercialInvoiceFile = (selectedFile, newFileName) => {
    var selectedFileParam = {
        "file" : selectedFile,
        "newFileName": newFileName
    }
    var selectedFileParams = [selectedFileParam];
    return uploadCommercialInvoiceFiles(selectedFileParams);
}

export const uploadCommercialInvoiceFiles = (selectedFileParams) => {
    const formData = new FormData();
    // 添加文件到FormData
    for (let i = 0; i < selectedFileParams.length; i++) {
        if (selectedFileParams[i]) {
            // console.log("selectedFileParams[i].file", selectedFileParams[i].file);
            // console.log("selectedFileParams[i].newFileName", selectedFileParams[i].newFileName);
            formData.append('files', selectedFileParams[i].file, selectedFileParams[i].newFileName);
        }
    }
    // 发送请求
    // 设置请求头 当用 fetch 发起formData请求时，无需设置contentType，浏览器会自己设置
    // // var myHeaders = new Headers();
    // // myHeaders.append("Content-Type", "multipart/form-data");
    // var requestOptions = {
    //     method: 'POST',
    //     // headers: myHeaders,
    //     body: formData,
    //     redirect: 'follow'
    // };

    // return fetch(YIDA_BACKEND_API_URL + "/yida/waybill-prediction/uploadCommercialInvoiceFiles", requestOptions);

    var request = new XMLHttpRequest();
    request.open("POST", YIDA_BACKEND_API_URL + "/yida/waybill-prediction/uploadCommercialInvoiceFiles", false);
    request.send(formData);
    // 获取响应或处理错误
    if(request.status === 200) {
        return JSON.parse(request.responseText).data;
    } else {
        console.error("Error occurred:", request.statusText);
        return [];
    }
}

export const asyncUploadCommercialInvoiceFiles = (selectedFileParams) => {
    const formData = new FormData();
    // 添加文件到FormData
    for (let i = 0; i < selectedFileParams.length; i++) {
        if (selectedFileParams[i]) {
            // console.log("selectedFileParams[i].file", selectedFileParams[i].file);
            // console.log("selectedFileParams[i].newFileName", selectedFileParams[i].newFileName);
            formData.append('files', selectedFileParams[i].file, selectedFileParams[i].newFileName);
        }
    }
    // 发送请求
    // 设置请求头 当用 fetch 发起formData请求时，无需设置contentType，浏览器会自己设置
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    var requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };

    return fetch(YIDA_BACKEND_API_URL + "/yida/waybill-prediction/uploadCommercialInvoiceFiles", requestOptions);

}
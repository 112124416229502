import { createStore } from 'vuex';

const store = createStore({
  state: {
    files: [],
    fileSettings: [],
  },
  mutations: {
    ADD_FILE(state, payload) {
      state.files.push(
              { 
                id: /*file.lastModified |*/ payload.index, 
                name: payload.file.name,
                newName: payload.file.name,
                uploaded: 0,
                serviceBaseUrl: '',
                url:'',
                boundCustomerOrderNo: '',
              });

      state.fileSettings[payload.index] = {
                customerName: '',
                customerCode: '',
                customerOrderNo: '',
                clientModel: {},
                deliveryCountry: '',
                deliveryCountryModel: {},
                shippingChannel: '',
                packageCount: 1,
                packageType: 1,
                customDeclarationMethod: 0,
                cargoCharacteristics : '普货',
                cargoCharacteristicsList: [], 
                createResult: {
                  status: 0,
                  success: false,
                  fileName: payload.file.name,
                  message: '创建失败',
                },
                choosed: false,
      }
    },
    GET_FILE(state, id) {
      return state.files[id]; 
    },
    UPDATE_FILE(state, { id, payload }) {
      state.files[id] = { ...state.files[id], ...payload };
    },
    RESET_FILES(state) {
      state.files = [];
    },
    GET_FILE_SETTINGS(state, id) {
      return state.fileSettings[id]; 
    },
    UPDATE_FILE_SETTINGS(state, { id, settings }) {
      state.fileSettings[id] = { ...state.fileSettings[id], ...settings };
    },
    RESET_FILE_SETTINGS(state) {
      state.fileSettings = [];
    },
    CHECK_FILE_SETTINGS(state, settings) {
      var checked = settings.clientModel !== undefined && settings.clientModel !== '{}'
                && settings.deliveryCountryModel !== undefined && settings.deliveryCountryModel !== '{}'
                && settings.customerName !== undefined && settings.customerName !== ''
                && settings.customerCode !== undefined && settings.customerCode !== ''
                && settings.customerOrderNo !== undefined && settings.customerOrderNo !== ''
                && settings.deliveryCountry !== undefined && settings.deliveryCountry !== ''
                && settings.shippingChannel !== undefined && settings.shippingChannel !== ''
                && settings.packageCount !== undefined && settings.packageCount > 0 && settings.packageCount !== '';
        return checked
    },
  },
  actions: {
    addFile({ commit }, payload) {
      commit('ADD_FILE', payload);
    },
    // getFile({ commit }, id) {  
    //   commit('GET_FILE', id);
    // },
    updateFile({ commit }, {id, payload}) {
      commit('UPDATE_FILE', { id, payload });
    },
    resetFiles({ commit }) {
      commit('RESET_FILES');
    },
    getFileSettings({ commit }, id) {  
      commit('GET_FILE_SETTINGS', id);
    },
    updateFileSettings({ commit }, { id, settings }) {
      commit('UPDATE_FILE_SETTINGS', { id, settings });
    },
    resetFileSettings({ commit }) {
      commit('RESET_FILE_SETTINGS');
    },
    checkFileSettings({ commit }, settings) {  
      commit('CHECK_FILE_SETTINGS', settings);
    }
  },
});

export default store;
<template>
  <img alt="Yida logo" src="@/assets/logo.png" class="logo" />
  <h2>深圳亿达国际供应链</h2>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/domain/login/LoginForm.vue';

export default {
  components: {
    LoginForm
  }
};
</script>